<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
                <b-button to="view">
                    <b-icon icon="info"/>
                    Просмотреть
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item || request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Телефон"
                    label-for="phone"
                >
                    <b-form-input
                        id="phone"
                        v-model="form.phone"
                        type="text"
                        placeholder="Введите телефон"
                        required
                        :state="errors.phone ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.phone"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: null,
            form: {
                id: null,
                phone: null,
            },
            errors: {},
            request: false,
        };
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        submit(event) {
            const id = parseInt(this.$route.params.id);

            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this.axios.patch(`/admin_api/users/${id}`, {...this.form})
                .then(async () => {
                    this.$alertify.success("Данные успешно изменены");
                })
                .catch(async ({response}) => {
                    if (response.status === 422) {
                        this.errors = response.data.errors;
                    }
                    this.$alertify.error(response.data.message);
                })
                .finally(async () => {
                    this.request = false;
                });

        },
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.axios.get(`/admin_api/users/${id}`).then(async ({data}) => {
                this.item = data;
                this.form.id = data.id;
                this.form.phone = data.phone;
            });
        },
    },
};
</script>
